import { createRequestActions } from 'src/lib/requestActions';
import { Publication } from 'src/common/models/publications';
import { createAction } from 'src/lib/createAction';

export const publicationsActions = {
  setPage: createAction('publications/setPage')<number>(),
  setFilters: createAction('publications/setFilters')<{
    device?: string;
  }>(),
} as const;

export const getPublicationsActions = createRequestActions(
  `publications/getPublications/request`,
  `publications/getPublications/requestSuccess`,
  `publications/getPublications/requestFailure`
)<undefined, Publication[], object>();

export const getPublicationsCountActions = createRequestActions(
  `publications/getCount/request`,
  `publications/getCount/requestSuccess`,
  `publications/getCount/requestFailure`
)<undefined, number, object>();
