import { createReducer } from '@reduxjs/toolkit';
import { wistiaPlayerActions } from 'src/state/wistiaPlayer/wistiaPlayerActions';

export interface WistiaPlayerState {
  mainPlayerPlaying: boolean | null;
}

const initialState: WistiaPlayerState = {
  mainPlayerPlaying: null,
};

export const wistiaPlayerReducer = createReducer<WistiaPlayerState>(
  initialState,
  (builder) =>
    builder.addCase(
      wistiaPlayerActions.setMainPlayerPlaying,
      (state, action) => {
        state.mainPlayerPlaying = action.payload;
      }
    )
);
