import { createAction } from 'src/lib/createAction';
import { createRequestActions } from 'src/lib/requestActions';
import {
  ContactExistsDTO,
  ContactExistsPayload,
  ContactRegistrationDTO,
  ContactRegistrationPayload,
  RegistrationLeadPayload,
} from 'src/common/models/registration';
import { VideoAcademyItem } from 'src/common/models/videoAcademy';

export const registrationActions = {
  setVideoUrl: createAction('registration/setVideoUrl')<string | null>(),
  setVideoItem: createAction(
    'registration/setVideoItem'
  )<VideoAcademyItem | null>(),
  toggleModal: createAction('registration/toggleModal')<boolean>(),
  toggleNewsletter: createAction('registration/toggleNewsletter')<boolean>(),
  goToInitStep: createAction('registration/goToInitStep')(),
  goToRegistrationStep: createAction('registration/goToRegistrationStep')(),
  logout: createAction('registration/logout')(),
} as const;

export const contactExistsActions = createRequestActions(
  `registration/contactExists/request`,
  `registration/contactExists/requestSuccess`,
  `registration/contactExists/requestFailure`
)<ContactExistsPayload, ContactExistsDTO, object>();

export const contactRegistrationActions = createRequestActions(
  `registration/contactRegistration/request`,
  `registration/contactRegistration/requestSuccess`,
  `registration/contactRegistration/requestFailure`
)<ContactRegistrationPayload, ContactRegistrationDTO, object>();

export const registrationLeadActions = createRequestActions(
  `registration/registrationLead/request`,
  `registration/registrationLead/requestSuccess`,
  `registration/registrationLead/requestFailure`
)<RegistrationLeadPayload, ContactRegistrationDTO, object>();

export const enableRegistrationBypass = createAction(
  'registration/bypass/enable'
)();
