import { combineReducers, Reducer } from 'redux';

export const createSagaInjector = (runSaga: any) => {
  const injectedSagas = new Map();

  const isInjected = (key: string) => injectedSagas.has(key);

  const injectSaga = (key: string, saga: () => Generator) => {
    if (isInjected(key)) return;
    const task = runSaga(saga);
    injectedSagas.set(key, task);
  };

  const deleteSaga = (key: string) => {
    if (!isInjected(key)) return;
    const task = injectedSagas.get(key);
    if (
      task &&
      task.hasOwnProperty('cancel') &&
      typeof task.cancel === 'function'
    ) {
      task.cancel();
    }
    injectedSagas.delete(key);
  };

  return { injectSaga, deleteSaga };
};

export const createReducerInjector = (
  root: object,
  replaceReducer: (nextReducer: Reducer) => void
) => {
  const injectedReducers: { [key: string]: Reducer } = {};

  const isInjected = (key: string) => injectedReducers.hasOwnProperty(key);

  const injectReducer = (key: string, reducer: Reducer) => {
    if (isInjected(key)) return;
    injectedReducers[key] = reducer;
    replaceReducer(combineReducers({ ...root, ...injectedReducers }));
  };

  const deleteReducer = (key: string) => {
    if (!isInjected(key)) return;
    delete injectedReducers[key];
    replaceReducer(combineReducers({ ...root, ...injectedReducers }));
  };

  return { injectReducer, deleteReducer };
};
