import { BaseAxios, throwErrorResponse } from 'src/common/services/axios';
import { flatten } from 'src/lib/axios';
import qs from 'qs';
import {
  GetPublicationsParams,
  Publication,
  publicationsPerPage,
} from 'src/common/models/publications';

export const getPublications = ({ page, filters }: GetPublicationsParams) => {
  const query = qs.stringify({
    _limit: publicationsPerPage,
    _start: (page - 1) * publicationsPerPage,
    _sort: 'date:desc',
    ...(filters.device ? { 'devices.id_eq': filters.device } : {}),
  });

  return BaseAxios.get<Publication[]>(`publications?${query}`)
    .then(flatten)
    .catch(throwErrorResponse);
};

export const getPublicationsCount = ({ filters }: GetPublicationsParams) => {
  const query = qs.stringify({
    ...(filters.device ? { 'devices.id_eq': filters.device } : {}),
  });

  return BaseAxios.get<string>(`publications/count?${query}`)
    .then(flatten)
    .then(Number)
    .catch(throwErrorResponse);
};
