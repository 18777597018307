import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  getPublicationsCount,
  getPublications,
} from 'src/common/services/publications';
import {
  getPublicationsCountActions,
  getPublicationsActions,
  publicationsActions,
} from 'src/state/publications/publicationsActions';
import { publicationsStateSelector } from 'src/state/publications/publicationsSelectors';

function* processGetPublications() {
  try {
    // eslint-disable-next-line
    // @ts-ignore FIXME
    const state = yield select(publicationsStateSelector);

    // eslint-disable-next-line
    // @ts-ignore FIXME
    const response = yield call(getPublications, {
      page: state.page,
      filters: state.filters,
    });
    yield put(getPublicationsActions.requestSuccess(response));
  } catch (error) {
    yield put(getPublicationsActions.requestFailure(error));
  }
}

function* processGetPublicationsCount() {
  try {
    // eslint-disable-next-line
    // @ts-ignore FIXME
    const state = yield select(publicationsStateSelector);

    // eslint-disable-next-line
    // @ts-ignore FIXME
    const response = yield call(getPublicationsCount, {
      page: state.page,
      filters: state.filters,
    });
    yield put(getPublicationsCountActions.requestSuccess(response));
  } catch (error) {
    yield put(getPublicationsCountActions.requestFailure(error));
  }
}

function* getPublicationsWatcher() {
  yield takeEvery(
    [
      getPublicationsActions.request.type,
      publicationsActions.setPage.type,
      publicationsActions.setFilters.type,
    ],
    processGetPublications
  );
}

function* getPublicationsCountWatcher() {
  yield takeEvery(
    [
      getPublicationsCountActions.request.type,
      publicationsActions.setFilters.type,
    ],
    processGetPublicationsCount
  );
}

export function* publicationsSagas() {
  yield all([getPublicationsWatcher(), getPublicationsCountWatcher()]);
}
