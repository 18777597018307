import { createAction } from 'src/lib/createAction';
import { map, none, some, Option, isNone } from 'fp-ts/lib/Option';
import { pipe } from 'fp-ts/lib/pipeable';
import { splitAt } from 'ramda';

export const createRequestActions =
  <A extends string, B extends string, C extends string>(
    name: A,
    successName: B,
    failureName: C
  ) =>
  <RequestPayload = void, SuccessPayload = void, FailurePayload = void>() =>
    ({
      request: createAction(name)<RequestPayload>(),
      requestSuccess: createAction(successName)<SuccessPayload>(),
      requestFailure: createAction(failureName)<FailurePayload>(),
    } as const);

export const splitRequestAction = (
  actionName: string
): Option<{
  name: string;
  state: 'request' | 'requestFailure' | 'requestSuccess';
}> => {
  const [name, state] = splitAt(actionName.lastIndexOf('/'), actionName).map(
    (v, i) => (i === 1 ? v.substr(1) : v)
  );

  if (!['request', 'requestFailure', 'requestSuccess'].includes(state)) {
    return none;
  }

  return some({
    name,
    state: state as 'request' | 'requestFailure' | 'requestSuccess',
  });
};

export const getRequestActionName = (actionName: string) =>
  pipe(
    splitRequestAction(actionName),
    map((_) => _.name)
  );

export const getRequestActionNameUnsafe = (actionName: string) => {
  const a = getRequestActionName(actionName);

  if (isNone(a)) {
    throw new Error(
      `${actionName} is now a valid request action and cannot be split!`
    );
  }

  return a.value;
};
