import { createReducer } from '@reduxjs/toolkit';
import {
  contactExistsActions,
  contactRegistrationActions,
  enableRegistrationBypass,
  registrationActions,
  registrationLeadActions,
} from 'src/state/registration/registrationActions';
import { VideoAcademyItem } from 'src/common/models/videoAcademy';

export enum RegistrationSteps {
  Exists = 'exists',
  Init = 'init',
  Registration = 'registration',
  Success = 'success',
  Inactive = 'inactive',
  Logout = 'logout',
}

export interface RegistrationState {
  currentStep: RegistrationSteps;
  email: string;
  newsletterSuccess: boolean;
  opened: boolean;
  videoUrl: string | null;
  videoItem: VideoAcademyItem | null;
  bypass: boolean;
}

const initialState: RegistrationState = {
  currentStep: RegistrationSteps.Init,
  email: '',
  newsletterSuccess: false,
  opened: false,
  videoUrl: null,
  videoItem: null,
  bypass: false,
};

export const registrationReducer = createReducer<RegistrationState>(
  initialState,
  (builder) =>
    builder
      .addCase(contactExistsActions.request, (state, action) => {
        state.email = action.payload.email;
      })
      .addCase(contactExistsActions.requestSuccess, (state, action) => {
        if (action.payload.active === false) {
          state.currentStep = RegistrationSteps.Inactive;
        } else {
          state.currentStep = action.payload.contact
            ? RegistrationSteps.Exists
            : RegistrationSteps.Registration;
        }
      })
      .addCase(contactRegistrationActions.requestSuccess, (state, action) => {
        state.currentStep = RegistrationSteps.Inactive;
      })
      .addCase(registrationLeadActions.requestSuccess, (state, action) => {
        state.newsletterSuccess = true;
      })
      .addCase(registrationActions.setVideoUrl, (state, action) => {
        state.videoUrl = action.payload;
      })
      .addCase(registrationActions.setVideoItem, (state, action) => {
        state.videoItem = action.payload;
      })
      .addCase(registrationActions.toggleModal, (state, action) => {
        state.opened = action.payload;
      })
      .addCase(registrationActions.toggleNewsletter, (state, action) => {
        state.newsletterSuccess = action.payload;
      })
      .addCase(registrationActions.goToInitStep, (state, action) => {
        state.currentStep = RegistrationSteps.Init;
      })
      .addCase(registrationActions.goToRegistrationStep, (state, action) => {
        state.currentStep = RegistrationSteps.Registration;
      })
      .addCase(registrationActions.logout, (state, action) => {
        state.currentStep = RegistrationSteps.Logout;
      })
      .addCase(enableRegistrationBypass, (state) => {
        state.bypass = true;
      })
);
