import { Action } from 'redux';
import { splitRequestAction } from 'src/lib/requestActions';
import { isNone } from 'fp-ts/lib/Option';

export interface RequestLoadingState {
  [action: string]: boolean;
}

export const requestLoadingReducer = (
  state: RequestLoadingState = {},
  action: Action
): RequestLoadingState => {
  const requestInfo = splitRequestAction(action.type);
  if (isNone(requestInfo)) {
    return state;
  }

  return {
    ...state,
    [requestInfo.value.name]: requestInfo.value.state === 'request',
  };
};
