import { isPlatformBrowser } from 'src/lib/isPlatformBrowser';

const apiUrl = process.env.GATSBY_API_URL;
const environmentName = process.env.GATSBY_APP_ENVIRONMENT_NAME;
const gtmId = process.env.GATSBY_GTM_ID;

export const environment = {
  apiUrl,
  environmentName,
  gtmId,
} as const;

export const prependApiUrl =
  environmentName === 'production'
    ? (_: string) => _
    : (_: string) => (_.startsWith('http') ? _ : apiUrl + _);

if (isPlatformBrowser()) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  window.version = {
    version: process.env.GATSBY_APP_VERSION,
    environmentName: process.env.GATSBY_APP_ENVIRONMENT_NAME,
  };
}
