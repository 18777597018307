import { Action } from 'redux';
import { splitRequestAction } from 'src/lib/requestActions';
import { isNone } from 'fp-ts/lib/Option';

export interface RequestTimestampState {
  [action: string]: number;
}

export const requestTimestampReducer = (
  state: RequestTimestampState = {},
  action: Action
): RequestTimestampState => {
  const requestInfo = splitRequestAction(action.type);

  if (isNone(requestInfo) || requestInfo.value.state === 'request') {
    return state;
  }

  return {
    ...state,
    [requestInfo.value.name]: Date.now(),
  };
};
