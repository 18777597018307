import { rem } from 'polished';

const fontSizeBase = 12;
const fontFamilyDefault = '"Lato", sans-serif';
const fontFamilyHeaders = '"Lato", sans-serif;';

export const typography = {
  fontSizeBase: rem(fontSizeBase),
  fontFamilyDefault,
  fontFamilyHeaders,
} as const;
