import React, { ReactNode } from 'react';
import { Provider } from 'react-redux';
import { store } from 'src/state';
import 'src/assets/styles.scss';
import { SnackbarProvider } from 'notistack';
import { StoreSnackbarController } from 'src/common/components/StoreSnackbarController';
import { ThemeProvider } from '@material-ui/core/styles';
import { muiTheme } from 'src/config/muiTheme';
import smoothscroll from 'smoothscroll-polyfill';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MediaContextProvider } from 'src/common/services/media';

// eslint-disable-next-line no-restricted-imports
import 'src/config/fonts';

smoothscroll.polyfill();

export const gatsbyBrowserRootWrapper = ({
  element,
}: {
  element: ReactNode;
}) => {
  return (
    <ThemeProvider theme={muiTheme}>
      <Provider store={store}>
        <MediaContextProvider>
          <SnackbarProvider
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              {element}
            </MuiPickersUtilsProvider>
            <StoreSnackbarController />
          </SnackbarProvider>
        </MediaContextProvider>
      </Provider>
    </ThemeProvider>
  );
};
