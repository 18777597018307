export const itemsPerPage = 5;

export interface Category {
  count: number;
  id: string;
  label: string;
  icon?: Media;
}

export interface Duration {
  id: string;
  label: string;
}

export interface Media {
  url: string;
}

export interface Language {
  id: string;
  label: string;
  icon: Media;
}

export interface Tag {
  id: string;
  label: string;
}

export interface Speaker {
  avatar: Media;
  description: string;
  id: string;
  name: string;
}

export interface VideoAcademyItem {
  description: string;
  duration?: Duration;
  id: string;
  languages?: Language[];
  speakers?: Speaker[];
  tags?: Tag[];
  thumbnail?: Media;
  title: string;
  wistia_url: string;
  detailed_description?: string;
}

export enum VideoAcademySortBy {
  Newest = 'DESC',
  Oldest = 'ASC',
}

export interface GetCategoriesDTO {
  durations: Category[];
  languages: Category[];
  subjects: Category[];
  total: number;
}

export interface GetItemsParams {
  activeDuration: string;
  activeLanguage: string;
  activeSortBy: VideoAcademySortBy;
  activeSubject: string;
  page: number;
}
