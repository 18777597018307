import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { createRootReducer, RootState } from 'src/state/reducer';
import {
  createReducerInjector,
  createSagaInjector,
} from 'src/lib/reduxInjectors';
import { registrationSagas } from 'src/state/registration/registrationSagas';
import { videoAcademySagas } from 'src/state/videoAcademy/videoAcademySagas';
import { devicesSagas } from 'src/state/devices/devicesSagas';
import { publicationsSagas } from 'src/state/publications/publicationsSagas';

function* rootSaga() {
  yield all([
    registrationSagas(),
    videoAcademySagas(),
    devicesSagas(),
    publicationsSagas(),
  ]);
}

const rootStateFactory = () => {
  const sagaMiddleware = createSagaMiddleware();
  const { injectSaga, deleteSaga } = createSagaInjector(sagaMiddleware.run);
  const enhancer = composeWithDevTools(applyMiddleware(sagaMiddleware));

  const rootReducer = createRootReducer();

  const store = createStore(combineReducers<RootState>(rootReducer), enhancer);
  const persistor = persistStore(store);
  const { injectReducer, deleteReducer } = createReducerInjector(
    rootReducer,
    store.replaceReducer
  );
  injectSaga('root', rootSaga);

  return {
    store,
    persistor,
    injectReducer,
    deleteReducer,
    injectSaga,
    deleteSaga,
  };
};

export const {
  store,
  persistor,
  injectReducer,
  deleteReducer,
  injectSaga,
  deleteSaga,
} = rootStateFactory();
