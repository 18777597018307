import { createReducer } from '@reduxjs/toolkit';
import { getDevicesActions } from 'src/state/devices/devicesActions';
import { Device } from 'src/common/models/devices';

export interface DevicesState {
  devices: Device[];
}

const initialState: DevicesState = {
  devices: [],
};

export const devicesReducer = createReducer<DevicesState>(
  initialState,
  (builder) =>
    builder.addCase(getDevicesActions.requestSuccess, (state, action) => {
      state.devices = action.payload;
    })
);
