import { createReducer } from '@reduxjs/toolkit';
import { modalActions } from 'src/state/modal/modalActions';

export interface ModalState {
  filters: boolean;
  hamburger: boolean;
}

const initialState: ModalState = {
  filters: false,
  hamburger: false,
};

export const modalReducer = createReducer<ModalState>(initialState, (builder) =>
  builder
    .addCase(modalActions.toggleFilters, (state, action) => {
      state.filters = action.payload;
    })
    .addCase(modalActions.toggleHamburger, (state, action) => {
      state.hamburger = action.payload;
    })
);
