import { createReducer } from '@reduxjs/toolkit';
import { Publication } from 'src/common/models/publications';
import {
  getPublicationsCountActions,
  getPublicationsActions,
  publicationsActions,
} from 'src/state/publications/publicationsActions';

export interface PublicationsState {
  page: number;
  items?: Publication[];
  total: number;
  filters: {
    device?: string;
  };
}

const initialState: PublicationsState = {
  page: 1,
  filters: {},
  total: 0,
};

export const publicationsReducer = createReducer<PublicationsState>(
  initialState,
  (builder) =>
    builder
      .addCase(publicationsActions.setPage, (state, action) => {
        state.page = action.payload;
      })
      .addCase(publicationsActions.setFilters, (state, action) => {
        state.filters = {
          ...state.filters,
          ...action.payload,
        };
        state.page = 1;
      })
      .addCase(getPublicationsActions.requestSuccess, (state, action) => {
        state.items = action.payload;
      })
      .addCase(getPublicationsCountActions.requestSuccess, (state, action) => {
        state.total = action.payload;
      })
);
