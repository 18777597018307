import { createReducer } from '@reduxjs/toolkit';
import {
  GetCategoriesDTO,
  Media,
  VideoAcademyItem,
  VideoAcademySortBy,
} from 'src/common/models/videoAcademy';
import {
  getCategoriesActions,
  getCountActions,
  getFeaturedActions,
  getItemsActions,
  videoAcademyActions,
} from 'src/state/videoAcademy/videoAcademyActions';

export interface VideoAcademyState {
  categories?: GetCategoriesDTO;
  currentPage: number;
  duration: string | null;
  featured?: {
    heroVideo?: Media;
    heroVideoMobile?: Media;
    videos: VideoAcademyItem[];
  };
  items?: VideoAcademyItem[];
  language: string | null;
  loading: boolean;
  sortBy: VideoAcademySortBy;
  subject: string | null;
  total: number;
}

const initialState: VideoAcademyState = {
  currentPage: 1,
  duration: null,
  language: null,
  loading: true,
  sortBy: VideoAcademySortBy.Newest,
  subject: null,
  total: 0,
};

export const videoAcademyReducer = createReducer<VideoAcademyState>(
  initialState,
  (builder) =>
    builder
      .addCase(getCategoriesActions.requestSuccess, (state, action) => {
        state.categories = action.payload;
      })
      .addCase(getCountActions.requestSuccess, (state, action) => {
        state.total = action.payload;
      })
      .addCase(getFeaturedActions.requestSuccess, (state, action) => {
        state.featured = action.payload;
      })
      .addCase(getItemsActions.requestSuccess, (state, action) => {
        state.items = action.payload;
      })
      .addCase(videoAcademyActions.clearFilters, (state) => {
        state.duration = null;
        state.language = null;
        state.subject = null;
      })
      .addCase(videoAcademyActions.setDuration, (state, action) => {
        state.duration = action.payload;
        state.currentPage = 1;
      })
      .addCase(videoAcademyActions.setLanguage, (state, action) => {
        state.language = action.payload;
        state.currentPage = 1;
      })
      .addCase(videoAcademyActions.setPage, (state, action) => {
        state.currentPage = action.payload;
      })
      .addCase(videoAcademyActions.setSubject, (state, action) => {
        state.subject = action.payload;
        state.currentPage = 1;
      })
      .addCase(videoAcademyActions.sortBy, (state, action) => {
        state.sortBy = action.payload;
      })
);
