import { rgba } from 'polished';

const black = '#000';
const grey = '#848282';
const grey1 = '#333';
const grey4 = '#bdbdbd';
const grey6 = '#F2F2F2';
const grey7 = '#424242';
const lightRed = '#cd0338';
const mineShaft2 = '#222222';
const white = '#fff';

const itpGold = '#AE995C';
const itpGrey = '#2D343A';
const itpLightGrey = '#EAEAEA';

export const colors = {
  black,
  grey,
  grey1,
  grey4,
  grey6,
  grey7,
  lightRed,
  mineShaft2,
  white,

  itpGold,
  itpGrey,
  itpLightGrey,

  itpGold07: rgba(itpGold, 0.7),
  itpGold09: rgba(itpGold, 0.9),
  itpGrey09: rgba(itpGrey, 0.9),

  cabSav: '#470A15',
  darkRed: '#740b19',
  grey105: rgba(grey1, 0.5),
  grey108: rgba(grey1, 0.8),
  grey2: '#4F4F4F',
  grey3: '#828282',
  grey409: rgba(grey4, 0.9),
  grey605: rgba(grey6, 0.5),
  mineShaft205: rgba(mineShaft2, 0.5),

  white03: rgba(white, 0.3),
  white05: rgba(white, 0.5),
  white06: rgba(white, 0.6),
  white08: rgba(white, 0.8),
  black05: rgba(black, 0.5),
  black08: rgba(black, 0.8),
  black09: rgba(black, 0.9),
} as const;
