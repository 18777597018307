import { all, call, put, takeEvery } from 'redux-saga/effects';
import { getDevicesActions } from 'src/state/devices/devicesActions';
import { getDevices } from 'src/common/services/devices';

function* processGetDevices() {
  try {
    // eslint-disable-next-line
    // @ts-ignore FIXME
    const response = yield call(getDevices);
    yield put(getDevicesActions.requestSuccess(response));
  } catch (error) {
    yield put(getDevicesActions.requestFailure(error));
  }
}

function* getDevicesWatcher() {
  yield takeEvery([getDevicesActions.request.type], processGetDevices);
}

export function* devicesSagas() {
  yield all([getDevicesWatcher()]);
}
