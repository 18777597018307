import { Device } from 'src/common/models/devices';

export interface Publication {
  id: string;
  date?: string;
  title: string;
  info?: string;
  filePdf: FilePdf;
  devices: Device[];
}

interface FilePdf {
  name: string;
  url: string;
}

export interface GetPublicationsParams {
  page: number;
  filters: {
    device?: string;
  };
}

export const publicationsPerPage = 8;
