import { createAction } from 'src/lib/createAction';
import {
  GetCategoriesDTO,
  Media,
  VideoAcademyItem,
  VideoAcademySortBy,
} from 'src/common/models/videoAcademy';
import { createRequestActions } from 'src/lib/requestActions';

export const videoAcademyActions = {
  clearFilters: createAction('videoAcademy/clearFilters')(),
  setDuration: createAction('videoAcademy/setDuration')<string | null>(),
  setLanguage: createAction('videoAcademy/setLanguage')<string | null>(),
  setPage: createAction('videoAcademy/setPage')<number>(),
  setSubject: createAction('videoAcademy/setCategory')<string | null>(),
  sortBy: createAction('videoAcademy/sortBy')<VideoAcademySortBy>(),
} as const;

export const getCategoriesActions = createRequestActions(
  `videoAcademy/getCategories/request`,
  `videoAcademy/getCategories/requestSuccess`,
  `videoAcademy/getCategories/requestFailure`
)<undefined, GetCategoriesDTO, object>();

export const getCountActions = createRequestActions(
  `videoAcademy/getCount/request`,
  `videoAcademy/getCount/requestSuccess`,
  `videoAcademy/getCount/requestFailure`
)<undefined, number, object>();

export const getFeaturedActions = createRequestActions(
  `videoAcademy/getFeatured/request`,
  `videoAcademy/getFeatured/requestSuccess`,
  `videoAcademy/getFeatured/requestFailure`
)<
  undefined,
  { heroVideo?: Media; heroVideoMobile?: Media; videos: VideoAcademyItem[] },
  object
>();

export const getItemsActions = createRequestActions(
  `videoAcademy/getItems/request`,
  `videoAcademy/getItems/requestSuccess`,
  `videoAcademy/getItems/requestFailure`
)<undefined, VideoAcademyItem[], object>();
