import {
  notificationReducer,
  NotificationState,
} from 'src/state/notification/notificationReducer';
import {
  registrationReducer,
  RegistrationState,
} from 'src/state/registration/registrationReducer';
import {
  requestStatusReducer,
  RequestStatusState,
} from 'src/state/requestStatus/requestStatusReducer';
import {
  requestTimestampReducer,
  RequestTimestampState,
} from 'src/state/requestTimestamp/requestTimestampReducer';
import {
  requestLoadingReducer,
  RequestLoadingState,
} from 'src/state/requestLoading/requestLoadingReducer';
import {
  videoAcademyReducer,
  VideoAcademyState,
} from 'src/state/videoAcademy/videoAcademyReducer';
import { devicesReducer, DevicesState } from 'src/state/devices/devicesReducer';
import { modalReducer, ModalState } from 'src/state/modal/modalReducer';
import {
  wistiaPlayerReducer,
  WistiaPlayerState,
} from 'src/state/wistiaPlayer/wistiaPlayerReducer';
import { PersistState } from 'redux-persist/es/types';
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import {
  publicationsReducer,
  PublicationsState,
} from 'src/state/publications/publicationsReducer';

interface PersistPartial {
  _persist: PersistState;
}

export interface RootState {
  modal: ModalState;
  notification: NotificationState;
  registration: RegistrationState & PersistPartial;
  requestLoading: RequestLoadingState;
  requestTimestamp: RequestTimestampState;
  requestStatus: RequestStatusState;
  videoAcademy: VideoAcademyState;
  publications: PublicationsState;
  devices: DevicesState;
  wistiaPlayer: WistiaPlayerState;
}

export const createRootReducer = () => ({
  modal: modalReducer,
  notification: notificationReducer,
  registration: persistReducer(
    {
      storage: sessionStorage,
      key: 'registration',
      whitelist: ['currentStep', 'email', 'bypass'],
    },
    registrationReducer
  ),
  requestLoading: requestLoadingReducer,
  requestTimestamp: requestTimestampReducer,
  requestStatus: requestStatusReducer,
  videoAcademy: videoAcademyReducer,
  publications: publicationsReducer,
  devices: devicesReducer,
  wistiaPlayer: wistiaPlayerReducer,
});
