import { RootState } from 'src/state/reducer';
import { createSelector } from 'reselect';
import { VideoAcademyState } from 'src/state/videoAcademy/videoAcademyReducer';

export const videoAcademyStateSelector = (state: RootState) =>
  state.videoAcademy;

export const videoAcademyCategoriesSelector = createSelector(
  videoAcademyStateSelector,
  (state: VideoAcademyState) => state.categories
);

export const videoAcademyCurrentPageSelector = createSelector(
  videoAcademyStateSelector,
  (state: VideoAcademyState) => state.currentPage
);

export const videoAcademyDurationSelector = createSelector(
  videoAcademyStateSelector,
  (state: VideoAcademyState) => state.duration
);

export const videoAcademyFeaturedHeroVideoSelector = createSelector(
  videoAcademyStateSelector,
  (state: VideoAcademyState) => state.featured?.heroVideo
);

export const videoAcademyFeaturedHeroVideoMobileSelector = createSelector(
  videoAcademyStateSelector,
  (state: VideoAcademyState) => state.featured?.heroVideoMobile
);

export const videoAcademyFeaturedVideosSelector = createSelector(
  videoAcademyStateSelector,
  (state: VideoAcademyState) => state.featured?.videos ?? []
);

export const videoAcademyItemsSelector = createSelector(
  videoAcademyStateSelector,
  (state: VideoAcademyState) => state.items
);

export const videoAcademyLanguageSelector = createSelector(
  videoAcademyStateSelector,
  (state: VideoAcademyState) => state.language
);

export const videoAcademySortBySelector = createSelector(
  videoAcademyStateSelector,
  (state: VideoAcademyState) => state.sortBy
);

export const videoAcademySubjectSelector = createSelector(
  videoAcademyStateSelector,
  (state: VideoAcademyState) => state.subject
);

export const videoAcademyTotalSelector = createSelector(
  videoAcademyStateSelector,
  (state: VideoAcademyState) => state.total
);
