import { getErrorMessages } from 'src/common/services/axios';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { snackbarActions } from 'src/state/notification/notificationActions';
import {
  contactExists,
  contactRegistration,
  registrationLead,
} from 'src/common/services/registration';
import {
  contactExistsActions,
  contactRegistrationActions,
  registrationLeadActions,
} from 'src/state/registration/registrationActions';

function* processContactExists(
  action: ReturnType<typeof contactExistsActions.request>
) {
  const { email } = action.payload;

  try {
    // eslint-disable-next-line
    // @ts-ignore FIXME
    const response = yield call(contactExists, email);
    yield put(contactExistsActions.requestSuccess(response));
  } catch (error) {
    yield put(contactExistsActions.requestFailure(error));
    yield all(
      getErrorMessages(error).map((message) =>
        put(
          snackbarActions.enqueueSnackbar({
            message,
            options: { variant: 'error' },
          })
        )
      )
    );
  }
}

function* processContactRegistration(
  action: ReturnType<typeof contactRegistrationActions.request>
) {
  try {
    // eslint-disable-next-line
    // @ts-ignore FIXME
    const response = yield call(contactRegistration, action.payload);
    yield put(contactRegistrationActions.requestSuccess(response));
  } catch (error) {
    yield put(contactRegistrationActions.requestFailure(error));
    yield all(
      getErrorMessages(error).map((message) =>
        put(
          snackbarActions.enqueueSnackbar({
            message,
            options: { variant: 'error' },
          })
        )
      )
    );
  }
}

function* processRegistrationLead(
  action: ReturnType<typeof registrationLeadActions.request>
) {
  try {
    // eslint-disable-next-line
    // @ts-ignore FIXME
    const response = yield call(registrationLead, action.payload);
    yield put(registrationLeadActions.requestSuccess(response));
  } catch (error) {
    yield put(registrationLeadActions.requestFailure(error));
    yield all(
      getErrorMessages(error).map((message) =>
        put(
          snackbarActions.enqueueSnackbar({
            message,
            options: { variant: 'error' },
          })
        )
      )
    );
  }
}

function* contactExistsWatcher() {
  yield takeEvery(contactExistsActions.request.type, processContactExists);
}

function* contactRegistrationWatcher() {
  yield takeEvery(
    contactRegistrationActions.request.type,
    processContactRegistration
  );
}

function* registrationLeadWatcher() {
  yield takeEvery(
    registrationLeadActions.request.type,
    processRegistrationLead
  );
}

export function* registrationSagas() {
  yield all([
    contactExistsWatcher(),
    contactRegistrationWatcher(),
    registrationLeadWatcher(),
  ]);
}
