import { BaseAxios, throwErrorResponse } from 'src/common/services/axios';
import { flatten } from 'src/lib/axios';
import {
  GetCategoriesDTO,
  GetItemsParams,
  itemsPerPage,
  Media,
  VideoAcademyItem,
  VideoAcademySortBy,
} from 'src/common/models/videoAcademy';
import { evolve, map } from 'ramda';
import { mediaUrlTransformer } from 'src/common/services/image';
import qs from 'qs';

const prepareParams = (selected: GetItemsParams) => {
  const params = [];

  if (selected.activeDuration) {
    params.push({ duration: selected.activeDuration });
  }

  if (selected.activeLanguage) {
    params.push({ ['languages.id_in']: selected.activeLanguage });
  }

  if (selected.activeSubject) {
    params.push({ ['subjects.id_in']: selected.activeSubject });
  }

  return params;
};

export const getCategories = () =>
  BaseAxios.get<GetCategoriesDTO>(`categories`)
    .then(flatten)
    .then(
      evolve({
        languages: map(
          evolve({
            icon: mediaUrlTransformer,
          })
        ),
      })
    )
    .catch(throwErrorResponse);

export const getCount = (selected: GetItemsParams) => {
  const params = prepareParams(selected);

  const query = qs.stringify({
    _where: params,
  });

  return BaseAxios.get<string>(`videos/count?${query}`)
    .then(flatten)
    .then(Number)
    .catch(throwErrorResponse);
};

export const getFeatured = () =>
  BaseAxios.get<{
    heroVideo?: Media;
    heroVideoMobile?: Media;
    videos: VideoAcademyItem[];
  }>(`featured-section`)
    .then(flatten)
    .then(
      evolve({
        heroVideo: mediaUrlTransformer,
        heroVideoMobile: mediaUrlTransformer,
        videos: map(
          evolve({
            speakers: map(
              evolve({
                avatar: mediaUrlTransformer,
              })
            ),
            languages: map(
              evolve({
                icon: mediaUrlTransformer,
              })
            ),
            thumbnail: mediaUrlTransformer,
          })
        ),
      })
    )
    .catch(throwErrorResponse);

export const getItems = (selected: GetItemsParams) => {
  const params = prepareParams(selected);

  const query = qs.stringify({
    _limit: itemsPerPage,
    _sort: `published_at:${VideoAcademySortBy.Newest}`,
    _start: (selected.page - 1) * itemsPerPage,
    _where: params,
  });

  return BaseAxios.get<VideoAcademyItem[]>(`videos?${query}`)
    .then(flatten)
    .then(
      map(
        evolve({
          speakers: map(
            evolve({
              avatar: mediaUrlTransformer,
            })
          ),
          languages: map(
            evolve({
              icon: mediaUrlTransformer,
            })
          ),
          thumbnail: mediaUrlTransformer,
        })
      )
    )
    .catch(throwErrorResponse);
};
