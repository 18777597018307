import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import {
  getCategories,
  getCount,
  getFeatured,
  getItems,
} from 'src/common/services/videoAcademy';
import {
  getCategoriesActions,
  getCountActions,
  getFeaturedActions,
  getItemsActions,
  videoAcademyActions,
} from 'src/state/videoAcademy/videoAcademyActions';
import {
  videoAcademyCurrentPageSelector,
  videoAcademyDurationSelector,
  videoAcademyLanguageSelector,
  videoAcademySortBySelector,
  videoAcademySubjectSelector,
} from 'src/state/videoAcademy/videoAcademySelectors';

function* getParams() {
  // eslint-disable-next-line
  // @ts-ignore FIXME
  const activeDuration = yield select(videoAcademyDurationSelector);

  // eslint-disable-next-line
  // @ts-ignore FIXME
  const activeLanguage = yield select(videoAcademyLanguageSelector);

  // eslint-disable-next-line
  // @ts-ignore FIXME
  const activeSortBy = yield select(videoAcademySortBySelector);

  // eslint-disable-next-line
  // @ts-ignore FIXME
  const activeSubject = yield select(videoAcademySubjectSelector);

  // eslint-disable-next-line
  // @ts-ignore FIXME
  const page = yield select(videoAcademyCurrentPageSelector);

  return {
    activeDuration,
    activeLanguage,
    activeSortBy,
    activeSubject,
    page,
  };
}

function* processGetCategories() {
  try {
    // eslint-disable-next-line
    // @ts-ignore FIXME
    const response = yield call(getCategories);
    yield put(getCategoriesActions.requestSuccess(response));
  } catch (error) {
    yield put(getCategoriesActions.requestFailure(error));
  }
}

function* processGetCount() {
  try {
    // eslint-disable-next-line
    // @ts-ignore FIXME
    const params = yield getParams();

    // eslint-disable-next-line
    // @ts-ignore FIXME
    const response = yield call(getCount, params);
    yield put(getCountActions.requestSuccess(response));
  } catch (error) {
    yield put(getCountActions.requestFailure(error));
  }
}

function* processGetFeatured() {
  try {
    // eslint-disable-next-line
    // @ts-ignore FIXME
    const response = yield call(getFeatured);
    yield put(getFeaturedActions.requestSuccess(response));
  } catch (error) {
    yield put(getFeaturedActions.requestFailure(error));
  }
}

function* processGetItems() {
  try {
    // eslint-disable-next-line
    // @ts-ignore FIXME
    const params = yield getParams();

    // eslint-disable-next-line
    // @ts-ignore FIXME
    const response = yield call(getItems, params);
    yield put(getItemsActions.requestSuccess(response));
  } catch (error) {
    yield put(getItemsActions.requestFailure(error));
  }
}

function* getCategoriesWatcher() {
  yield takeEvery(getCategoriesActions.request.type, processGetCategories);
}

function* getCountWatcher() {
  yield takeEvery(
    [
      getCountActions.request.type,
      videoAcademyActions.clearFilters.type,
      videoAcademyActions.setDuration.type,
      videoAcademyActions.setLanguage.type,
      videoAcademyActions.setSubject.type,
      videoAcademyActions.sortBy.type,
    ],
    processGetCount
  );
}

function* getFeaturedWatcher() {
  yield takeEvery([getFeaturedActions.request.type], processGetFeatured);
}

function* getItemsWatcher() {
  yield takeEvery(
    [
      getItemsActions.request.type,
      videoAcademyActions.clearFilters.type,
      videoAcademyActions.setDuration.type,
      videoAcademyActions.setLanguage.type,
      videoAcademyActions.setPage.type,
      videoAcademyActions.setSubject.type,
      videoAcademyActions.sortBy.type,
    ],
    processGetItems
  );
}

export function* videoAcademySagas() {
  yield all([
    getCategoriesWatcher(),
    getCountWatcher(),
    getFeaturedWatcher(),
    getItemsWatcher(),
  ]);
}
