import { createMedia } from '@artsy/fresnel';

const AppMedia = createMedia({
  breakpoints: {
    px0: 0,
    px375: 375,
    px576: 576,
    px768: 768,
    px992: 992,
    px1200: 1200,
    px1366: 1366,
    px1440: 1440,
    px1600: 1600,
  },
});

// Make styles for injection into the header of the page
export const mediaStyles = AppMedia.createMediaStyle();
export const { Media, MediaContextProvider } = AppMedia;
