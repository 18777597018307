import Axios, { AxiosError } from 'axios';
import { environment } from 'src/config/environment';
import { ErrorMessages, ErrorResponse } from 'src/common/models/error';

// ------------------- http clients

export const BaseAxios = Axios.create({
  baseURL: environment.apiUrl,
});

// ------------------- error handling

// https://github.com/axios/axios#handling-errors
export const toErrorResponse = (error: AxiosError): ErrorResponse => {
  if (error.isAxiosError) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      return error.response.data;
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      return {
        errors: [
          {
            data: error.request,
            errorCode: '-1',
            message: 'Unexpected request error',
          },
        ],
      };
    } else {
      // Something happened in setting up the request that triggered an Error
      return {
        errors: [
          { data: error.message, errorCode: '-1', message: 'Internal error' },
        ],
      };
    }
  } else {
    return {
      errors: [
        { data: error, errorCode: '-1', message: 'Unexpected error occurred' },
      ],
    };
  }
};

export const throwErrorResponse = (error: AxiosError) => {
  throw toErrorResponse(error);
};

export const getErrorMessages = (
  response: ErrorResponse,
  messages?: ErrorMessages
): string[] => {
  console.log(response);

  return response.errors.map((error) => {
    if (messages) {
      return (
        messages[error.message ?? error.errorCode ?? 'default'] ?? error.message
      );
    }

    return error.message;
  });
};
