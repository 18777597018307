import { createMuiTheme } from '@material-ui/core/styles';
import { darken, lighten } from 'polished';
import { typography } from 'src/config/typography';
import { colors } from 'src/config/colors';

export const muiTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      light: lighten(0.2, colors.itpGold),
      main: colors.itpGold,
      dark: darken(0.2, colors.itpGold),
    },
  },
  typography: {
    fontFamily: typography.fontFamilyDefault,
  },
});
