import { BaseAxios, throwErrorResponse } from 'src/common/services/axios';
import { flatten } from 'src/lib/axios';
import {
  ContactExistsDTO,
  ContactRegistrationDTO,
  ContactRegistrationPayload,
  RegistrationLeadPayload,
} from 'src/common/models/registration';

export const contactExists = (email: string) =>
  BaseAxios.get<ContactExistsDTO>(`newsletter/contacts/${email}`)
    .then(flatten)
    .catch(throwErrorResponse);

export const contactRegistration = (data: ContactRegistrationPayload) =>
  BaseAxios.post<ContactRegistrationDTO>(`newsletter/contacts`, data)
    .then(flatten)
    .catch(throwErrorResponse);

export const registrationLead = (data: RegistrationLeadPayload) =>
  BaseAxios.post<ContactRegistrationDTO>(`newsletter/leads`, data)
    .then(flatten)
    .catch(throwErrorResponse);
