import { createSelector } from 'reselect';
import { RootState } from 'src/state/reducer';
import { PublicationsState } from 'src/state/publications/publicationsReducer';

export const publicationsStateSelector = (state: RootState) =>
  state.publications;

export const publicationsCurrentPageSelector = createSelector(
  publicationsStateSelector,
  (state: PublicationsState) => state.page
);

export const publicationsFiltersSelector = createSelector(
  publicationsStateSelector,
  (state: PublicationsState) => state.filters
);

export const publicationsItemsSelector = createSelector(
  publicationsStateSelector,
  (state: PublicationsState) => state.items
);

export const publicationsTotalSelector = createSelector(
  publicationsStateSelector,
  (state: PublicationsState) => state.total
);
